import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout.js";

const John = () => {
  return (
    <Layout>
      <Head title="John Howard" />
      <h3>John Howard</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Berkeley
      </h4>
      <p>
        <OutboundLink href="https://www.monkeyink.com/ink/">
          Website
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.facebook.com/JohnHowardArt">
          Facebook
        </OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://www.instagram.com/monkeyink/">
          Instagram
        </OutboundLink>
      </p>

      <p style={{ marginBottom: 10, visibility: "hidden" }}>*******</p>

      <h3>Interview by Edward Alvarez</h3>

      <p>
        John Howard creates concert posters and artwork for some of the most
        intriguing musicians in the world. I had an insightful conversation with
        him about his art and future projects. His linework is both incredibly
        intricate and unforgettable. John also excels at making 3D art which he
        draws by hand. I'm grateful to have had the opportunity to speak to him.
        His posters will continue to inspire and stun those who see them.
      </p>

      <p style={{ marginBottom: 10, visibility: "hidden" }}>*******</p>

      <p className="interviewItalic">
        Edward Alvarez: I remember the first time that I saw your art was from
        an Acid Mothers Temple poster in 2012. That poster has lived on since
        then. When I first saw it, it was unbelievable. And I remember, when I
        went to the concert I was so blown away by the artwork, I begged my
        friend to buy it for me, because I just barely didn't have enough money
        for it. So, I guess it's a story on its own how I ended up getting it.
        I've had it framed ever since. It's something that still amazes me every
        day when I look at it, I still have the same kind of magical feeling
        that I first had when I saw it. And I just think that it's one of my
        favorite posters. Maybe you can tell the story?
      </p>

      <p>
        John Howard: That started out as, generally when I work with Acid
        Mothers, he just sends me the name of the tour, and I put on the music
        and go from there. I'm always really inspired by those guys. I love
        them. They're very creative and very sweet people. They stayed with me a
        few times when they come through. And so from the get-go, I'm usually on
        a wavelength with them. Just their music hits me in a good way. And so
        this particular tour, 2012 was supposed to be the end of the Mayan
        calendar and the end of the world. And so it was doing a play on that
        whole idea, of this being their "Last Tour" because it was the end of
        the world.{" "}
      </p>

      <p className="interviewItalic">
        EA: That's exactly it, it was an interesting time.{" "}
      </p>

      <p>
        JH: The general idea of the poster, just to sort of talk about the idea
        of it, is, in the middle is the world- in the middle of the Mayan
        calendar. And there's a Mayan god in the middle of it there, and the
        world is draining out, there's a level of, it's almost as if it's a
        glass globe in the insides, an orange color liquid is draining out of
        it, and the draining out goes over a figure directly under the world and
        then the figure holding up the world is completely covered in that as it
        is running out of the world. And then down on the ground I guess it's
        sort of an alien looking landscape but it's actually a place that I
        visited many times in Northern California. And then there's several of
        the little Mayan guys that have already jumped off the calendar and
        they're on the ground down there and they're kind of like, we're out of
        here, this is over. That sort of basically, generally describes the way
        it came together for me. I try to leave things open enough that
        everybody can interpret it the way they want to.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/acidmothers2012-600.jpg"
        alt="Acid Mothers Temple poster by John Howard"
      />

      <p className="interviewItalic">
        EA: I do kind of have a whimsical impression when I see the characters
        on the bottom. But at the same time they are like Mayan symbols, so yea
        it's really interesting. I also liked, kind of like stars in the
        background, or I'm not sure if it's snow. The whole thing is just so
        creative. How did you start working with them, was there a story about
        how you found out about them?
      </p>

      <p>
        JH: Yea, I had done their U.S. tour poster for 12 years. And so, a while
        back a friend of mine who was a fan of theirs before I really was clued
        into him and stuff, just suggested that I should check him out, that he
        thought my work would go well with them. And I can't remember quite how,
        but one way or another I connected to them. Pretty much from the get-go
        things just fell into place really well, artistically and personally. I
        like him very much. It's funny, we've had talks when he stays over, when
        they're on tour, and we clocked out of our day jobs around the same time
        in the mid-90s, and took the leap into trying to take what we did in a
        creative way and make that how we lived, you know? And so, yea, shared
        respect on that a little bit.{" "}
      </p>

      <p className="interviewItalic">
        EA: That's really interesting. Have you met every musician that you've
        made art for?{" "}
      </p>

      <p>
        JH: Not everybody. Most of them, but not everyone. Sometimes I don't
        exactly want to, but most of the time it's a good thing. I've done a lot
        of work for the Swans and I would like to be friends with them. I did
        some posters for them, we were both at a festival in Barcelona at the
        same time, and they invited me to come over, and just the timing wasn't
        good on it. I had a booth of posters at the time. I don't know,
        sometimes it just doesn't work out. I don't have regrets necessarily
        about that because I mean, for whatever reason I'm sure it's for the
        best. It's really the music that I'm working with when I'm doing the
        art. I feel like I'm one of the fans speaking to the other fans through
        the art. So mostly meeting the musicians or not is, really it's almost a
        completely separate thing that can exist or not, you know?{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/swans800.jpg"
        alt="Swans poster by John Howard"
      />

      <p className="interviewItalic">
        EA: I remember I went to an Acid Mothers Temple concert and I got close
        to having a conversation with Kawabata. Kind of, going off of what you
        were saying, that you don't always want to meet them. I'll just say
        really quick that I was a little bit kind of star struck. I had admired
        him for so long, but when I finally met him I didn't really know what to
        say. Like my mind kind of went blank. So I guess it could be really
        inspiring to meet your idols, sometimes it can also be kind of
        stressful. But it sounds like you had some great experiences though.{" "}
      </p>

      <p>
        JH: Oh yea, definitely, definitely. But yea, it's like I was saying,
        it's a separate thing. If you're meeting someone that you're working
        with and you think of them in terms of being an idol- it has to be a
        little more even than that for it to be a good meeting, just person to
        person. I don't think either version of meeting the person really makes
        a difference, as far as being able to respond to the music with the
        artwork.{" "}
      </p>

      <p className="interviewItalic">
        EA: Right. So, did you grow up in Berkeley, have you always been there?{" "}
      </p>

      <p>
        JH: No, no I grew up in central Kentucky. Went to a college there, and
        it had a nice Art department. I was in the BFA painting program, and
        then just felt that I would learn more in the world than I was, that I
        had gotten what I could from that school. So a friend and I basically
        sold everything, got on a Greyhound and came to California. This has
        been my home ever since.{" "}
      </p>

      <p className="interviewItalic">
        EA: Hmm, wow. What about the art scene in Berkeley? The last times that
        I went there I remember it was really unique, there's so many creative
        people there. I'm sure it must be something special for you?{" "}
      </p>

      <p>
        JH: Somewhat. Honestly, I show work all over and I do poster work for
        events all over, and I actually show work more often away from this area
        than I do in this area over the years. Regular shows I do are in Mexico
        City, Barcelona. I haven't done Hamburg in a while, but I've done
        Hamburg and several German cities as well. I do South by Southwest in
        Texas pretty much every year. So even though I live here, I've gotten a
        little closer lately, but I'm really not very in touch with the Oakland
        and Berkeley art scene very much. I just recently have, so we connected
        with a gallery here, done a couple of things and maybe some more things
        coming up possibly. But yea it's kind of strange, I've only done a
        couple of posters for this area as well, not that many, for whatever
        reason it just worked out that way.{" "}
      </p>

      <p className="interviewItalic">
        EA: That's really interesting. Are there any artists that mystify you by
        their precision, or creativity?{" "}
      </p>

      <p>
        JH: Probably more creativity than precision. Something like precision or
        skill or that kind of thing, you almost have to get to a point that
        becomes invisible. At least for me, I certainly don't think of art as
        degrees of representing reality, photographically or otherwise. So in
        that sense the idea of precision is, can almost be a negative, where the
        attempting to, you know, pull off a trick or something like that in the
        background through technique or whatever, can often work against it.
        What I try to do is, a lot of the time when I'm working on things, on
        posters- I'm listening to the music and I just start working. I don't
        start with a big plan. I just start working, and through the work comes
        the idea. I think if I had a big idea and set down to illustrate it, I
        would be losing a lot of what could be in the work. It's like a lot of
        times people look at my work and go, "I don't see how you thought of
        that." And I didn't, it came about during the making of it. And you know
        there's a lot of little thoughts and decisions along the way that made
        it become something. Sometimes I almost feel like I'm sitting in with
        the band when I'm working, because it's all happening in the moment, I'm
        not filling in the lines of some idea from a different point in time.
        It's in the moment with the music, working on stuff, and stuff comes up
        that I didn't plan in the beginning, but it's all part of this one
        thing. So it's not like it doesn't fit together, it does fit together,
        different than I could think of, or anybody.{" "}
      </p>

      <p className="interviewItalic">
        EA: Actually it's fascinating the way that you explain that, and it
        reminds me of this other question that I have. Does synchronicity have
        any place in your art?{" "}
      </p>

      <p>
        JH: Yea, as much as anything, more than anything. I try to set up
        situations that allow for that. Always, constantly try to make room for
        that kind of thing, 100%.{" "}
      </p>

      <p className="interviewItalic">
        EA: In your journey as an artist, do you experience a lot of
        coincidences? Like things that you see, things that fall into place and
        that sort of thing?{" "}
      </p>

      <p>
        JH: That's exactly what it's all about. And it happens all the time.
        It's almost, instead of turning up control, the point is to turn down
        resistance.{" "}
      </p>

      <p className="interviewItalic">EA: Do you make your 3D art by hand? </p>

      <p>
        JH: Yea, it's drawn by hand, and it's drawn as separate elements and
        then put together. They have to be drawn separately because you have to
        work with each element separately to be able to put it in space in a
        different place in space. So you have to be able to get to them
        individually to be able to work with them to make them 3D in space.
        Otherwise if you do it all as one thing, that 3D would just come off
        evenly, they have to each be adjusted slightly differently to be in
        different places in space.{" "}
      </p>

      <img
        src="https://res.cloudinary.com/benjifri/image/upload/v1663348044/artnow/oneoffs/octopusgarden800-min.jpg"
        alt="Octopus poster by John Howard"
      />

      <p className="interviewItalic">EA: How did you figure that out? </p>

      <p>
        JH: I've been fascinated by 3D for a long time. I've got a collection of
        View-Masters. I've got the very old-fashioned ones...I've always been
        interested in how things work visually, and you know throughout the
        history of art, making something appear to be not on a flat plane on the
        wall, but in space, from iconography to the first developments of
        perspective, it's all been about that. I've always had an interest in
        that, but never really a reason to pursue it. And then I picked up 3D
        cameras along the way. I've got one that's got four lenses that you can
        do stuff with, but I never really thought of trying to do it in the art
        I produced until quite a few years ago I got a gig to do a poster for
        The Dandy Warhols. And at that time their website was in the red-blue
        3D, but it was in photographs. And so it just made me wonder if it was
        possible to do that in screen printing. So I experimented a little bit,
        and not only was it possible, it was a lot of fun. Because I mean,
        screen printing, as you know, is very flat matte color. It's strong
        colors but it's very flat and matte...very graphic and flat, and to
        break that surface with 3D is a lot of fun. And basically I got a little
        bit addicted to the whole idea and then you know, people would ask for
        them, for one thing or the other. Faith No More requested some, and
        Puscifer. And every time I do one I learn a little something because I
        mean, basically the whole idea of it is pretty simple physics. Like most
        things, there are a lot of exceptions and just a lot of little things
        you can do that can make it work better and differently. For example, in
        all the 3D posters I've done except for one, everything that comes off
        the surface has to be away from the outside edges, because if you're
        trying to visually pull something off the surface and it's at the edge,
        the edge will pull it back. And I found on that Puscifer poster that on
        the bottom of that one, there are two hands that are like this- coming
        off the side and just the top of the hands are drawn, but not the
        bottom; but your mind automatically draws the bottom. So in that case I
        was able to make the hands run off the corner but still come way out in
        front and so I mean, it sounds small and nerdy or something, a little
        bit, but you know, in there working on it little things like that are,
        they're really fun, they're like little golden discoveries that you make
        along the way that keeps it all interesting and intriguing.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/puscifer800.jpg"
        alt="Puscifer poster by John Howard"
      />

      <p className="interviewItalic">
        EA: I have that 3D poster that you made of the octopus and I try to
        analyze it sometimes with the 3D glasses and the whole time in my mind
        I'm just like, "How did he do this?" It's really interesting and it
        sounds like it's also something that you enjoy doing. Were you ever
        influenced by MC Escher?{" "}
      </p>

      <p>
        JH: Yea, definitely. I don't know exactly particularly how, but I've
        always appreciated his work. He creates a lot of depth, and then once he
        pulls you into this depth, of things going this way, he says no that
        stairwell is upside down, you know? I mean, so yea, I'm very interested
        in the little games he plays within his imagery. I don't know that
        there's anything I can think of in particular that I did as a direct
        result of something particular that he did, but it's definitely
        influenced me in general.{" "}
      </p>

      <p className="interviewItalic">
        EA: Yea I know what you mean about the depth and it had a lot to do with
        perspective also, I guess it was all mathematical somehow. Maybe can you
        talk about the book that you made? Night Walk?{" "}
      </p>

      <p>
        JH: Yea, that was a pandemic project. The general way that I put it
        together was a lot like I was talking about before, about working on
        separate images where there was not a decision of what this is going to
        be in advance of making it, what it is became what it is on the way.
        Basically I did a lot of drawings about it, about the general idea, and
        brought some older drawings in as well that related, and started putting
        it together and it was originally going to be just a book of drawings.
        It took about a year to put it all together, a little over a year, and
        as I started compiling it and as I was working on it, trying to
        understand what it was, different parts of it started connecting in a
        way that had a flow. And as time went by and as I was trying to figure
        it out, basically I had to name the drawings to know where I was on it,
        because a page number wasn't really happening yet. Because I was working
        from the inside out in different areas. And so as it came together, the
        names that I gave these different drawings, they started becoming
        phrases in it and they were connecting as well, and so the whole thing
        just grew together from the inside out, and much like the poster we
        talked about, in the end it's a lot like that. I have a pretty clear
        understanding about what it's all about, not so clear that it's not
        possible for it to evolve a little bit with me too, but I have a pretty
        clear feel for what the whole thing is about, definitely where it came
        from and everything, but I'm not really wanting to talk about that more
        than saying that, because it's definitely open-ended enough that I don't
        want to get in the way of other people's interpretation of it. And I
        think it's been fairly successful. I'm much more comfortable working
        with imagery in that way than words. Because with imagery, I've been
        working on this stuff so long that it's very natural for me to know how
        to get out of the way of what's happening and not lose a cohesiveness to
        it. And so it was a little trickier to do that with words, that's what
        took so long. There was one point where basically it took me like three
        months to take four pages out of it that made it finally work. So it was
        interesting. It was a very good project to do. When I say it took
        removals for pages it wasn't a painful, worrying, trying thing, just
        have to get away from it for a while and then come back with fresh eyes
        and go, "oh, maybe that." I've got other projects going on like crazy
        and then come back to it later, until it's finally like, that's it.
        There's nothing more I can do, this is done, you know?{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/flipthru2.gif"
        alt="John Howard flipping through book"
      />

      <p className="interviewItalic">
        EA: Yea, that book seems really interesting. I didn't know that you had
        done a book, I kind of found out about this the other day, but yea it
        seems like it turned out great.{" "}
      </p>

      <p>
        JH: Yea, I was very happy with it. I did it in the United States and so
        it was a little expensive to make it that way instead of going through
        China and stuff, but I was really able to do it in a way that was really
        just exactly like I wanted. The imagery on the cover, and the way they
        did the cover. And we did extra thick pages, it's really got a solid
        feel to it. And they had a special way of doing the blacks, got very
        intense blacks, on a slightly cream, heavy paper. And so what I did is,
        I did a limited edition of only 100 of them and signed and numbered
        them. I basically treated them like they were a print and so it's a
        signed numbered edition of 100, and then I did some two-color prints to
        go with them. I include a few in each book, six prints of the same size
        in each book. So my intention was, even though it was more expensive, to
        get it done in the best way I could. And I got some really good people
        working with me on that, they really pulled out the stops on coming up
        with some ideas about how to make it a substantial thing. Yea, just
        really grateful for a lot of help on that, and yea I'm just super happy
        with that. I've still got probably about, I don't know exactly how many,
        but I've got a few left, and I've got some events coming up, so probably
        between now and December...They're actually selling pretty much exactly
        like a print on release. Sold quite a few and then I'll be out there in
        the world by Christmas time. And I kind of love that it's like, that one
        project is that stack of books and that's it.{" "}
      </p>

      <p className="interviewItalic">
        EA: I kind of like that about it, it sounds like it's gonna be some sort
        of collector's item.{" "}
      </p>

      <p>
        JH: Yea maybe. I hope people want to keep it you know, but yea, I think
        it's fun. I like things like that, that when I have them I know that
        they're a little bit unique. I'm working on maybe another book but that
        one's definitely going to be more available.{" "}
      </p>

      <p className="interviewItalic">
        EA: Oh that's really good news though, I was wondering if you were
        planning to do more books, so that's awesome.{" "}
      </p>

      <p>
        JH: Yea, I really enjoyed the whole process of that and I'm definitely
        gonna do some more.{" "}
      </p>

      <p className="interviewItalic">
        EA: I have these books from Japan that have a special piece of film that
        you move across the page and it makes the artwork look like it's moving,
        is that something you would consider if you figured out how to do it?{" "}
      </p>

      <p>
        JH: Yea, yea I know a little bit about that. That's definitely
        something...And also, previous to this I did a couple of 3D books that
        sold out. They were screen printed and then hand put together, a dozen
        pages for each one, little books like that. So yea definitely the
        messing with the eyes, and motion and all that, I'm definitely
        interested in all that for sure.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/sandoftheseashore800.jpg"
        alt="3D Sand of the Sea Shore, 5 color anaglyphic serigraph on heavy stock, poster by John Howard"
      />
      <p className="interviewItalic">
        EA: Building off of what you were talking about earlier, that everybody
        has their own interpretations. It reminds me of this Ralph Ellison
        quote. He said, "once introduced into society, the work of art begins to
        pulsate with those meanings, emotions and ideas brought to it by its
        audience." So I guess it makes sense that you don't want to sway people
        too much. Some of the value is in wondering what everything means.{" "}
      </p>

      <p>
        JH: And like he alluded to there, once something gets in the world it
        takes on other aspects. Just like, this is somewhat parallel, you hear
        how a band will have some songs to put out a record, and then once they
        take the music on the road the songs evolve. From being in the world
        they take on other aspects. They're performed again and again, but in
        this case they're just seen again from another perspective outside of,
        you know, my drawing board, or whatever.{" "}
      </p>

      <p className="interviewItalic">
        EA: Have you ever experienced doubt as an artist?{" "}
      </p>

      <p>
        JH: Well, not in the way I think you mean. If you mean like having a
        blank piece of paper and being intimidated by it, not knowing what to
        do, that kind of artist-block kind of thing, no. But doubt plays a part
        in the making of stuff. An example is that it happens fairly often. I'll
        be working on something, and it doesn't seem to be going that well, but
        at this point, I've been doing this a long time, just in making images
        period. And at this point I recognize that as- my intentions are getting
        in the way of what's actually happening on the paper. I've got these
        intentions, this lens of intentions that I'm looking through, and as
        much as I mostly don't think that way, every now and then I have to walk
        away from something to be able to come back and see what it is outside
        of what I was trying to necessarily do. It's almost never that it's not
        something, it's almost always that I have to get my head on straight to
        see it a little bit, you know? I have to get away and come back to see
        what it is, and then I can go from there.{" "}
      </p>

      <p className="interviewItalic">
        EA: It sounds like you just sit down and start working and you don't
        really overthink things too much, or get too intimidated by things. So
        that's a good quality, and I guess that's how things have to be done.
        Especially with writing I feel like, I could think about something all
        day, but at the end of the day just have to sit down and start writing.{" "}
      </p>

      <p>
        JH: Exactly. Yea, it's almost that "10% inspiration, 90% perspiration"
        quote, I think.{" "}
      </p>

      <p className="interviewItalic">
        EA: Tell me about your woven tapestries, do they begin as a drawing or
        painting?{" "}
      </p>

      <p>
        JH: They're huge, they're like 60 inches. Yea, they're fun. A lot of
        fabric stuff these days are printed but these are woven. I found these
        guys back east that still do that with the big looms. I worked with them
        on three different ones. Actually five, but I only ended up publishing
        three of them. But it was great. It was cool to work with them, to go
        back and forth on the colors to get them as intense and bright as
        possible. And yea I just got the idea one time at Outside Lands
        actually. Outside Lands did a smaller blanket with their logo on it that
        they were selling to people and it was just so cool because these kids
        are walking around the festival with this blanket wrapped around both of
        them and it was just so cozy and cool looking. And I wanted to make
        something with my art that did that, and also something that would be
        good for people to have in their house too. It just seemed like a really
        cool thing to do, to take it from an illustration to a woven fabric
        thing, I've always wanted to do more textile type stuff. Yea, I designed
        it. It's basically a combination of drawings that I designed for the
        specific purpose.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images//tapestries1/catapestry1800.jpg"
        alt="John Howard Cat Tapestry"
      />

      <p className="interviewItalic">
        EA: So do you think art is always about money, or do you think it's
        possible to make art and not always think about money?{" "}
      </p>

      <p>
        JH: I do think that, yea definitely do. Basically for me art has been a
        lifelong mission that I'm really grateful for. I was talking to somebody
        recently about this. It's pretty common for people to not know what it
        is they want to do, and I know the feeling. I felt that feeling before,
        but especially in the last, I don't know how many years, a long time,
        that has been my mission in my life- to make art. I do posters because
        at a certain point in my life I was between jobs and on unemployment,
        and I decided to try to marry the things that I cared about, art and
        music, and see if I could sustain myself on it. And that's generally
        worked out pretty well, but it's always been about the making of the
        art. Since I was a kid it's always been about that first, and I've been
        very lucky that that's been a big part of my life. It's gotten me
        through a lot of shitty times in my life, that I had my purpose, I just
        had it. Really success is being able to do it another day. And so to
        that extent, money is somewhat involved because if you can't eat, you
        can't do it another day, but the real point, the real success for me is,
        very honestly, just being able to do it today.{" "}
      </p>

      <p className="interviewItalic">
        EA: Yea, that's a really good way to put it. I couldn't have said it
        better myself. I think that's a very valuable insight. Do you remember
        your dreams? Does any of your art come from your dreams?{" "}
      </p>

      <p>
        JH: Yea, yea. In fact, this is a whole different subject, a whole
        different interview, or whatever probably, but over Covid I've had some
        personal projects going on that are very, very much about that in a
        couple of different ways. The easiest way to say it is, I don't have a
        dream and then go draw it, because that would be back to the thing where
        I'm just tracing things from a previous time, instead of working in the
        moment. And this project, it's been going on for about a year and a half
        now, it has to do with basically, one way to look at it is, the pictures
        I'm making are dreams. They start off in chaos and confusion, not in a
        bad way, but just trying to say that I start out- again not knowing what
        it's going to be, and then it progresses, and I start to make a little
        bit of sense, I start bringing out the things that seem to be happening,
        and then it's over, just like a dream. I've been doing a lot of art
        coming very specifically from that point of view for the last maybe 20
        some months, something like that.{" "}
      </p>

      <p className="interviewItalic">
        EA: Really interesting what you said about dreams. And actually, I'm
        kind of curious, do you ever remember a lot of detail in your dreams,
        like are they kind of realistic?{" "}
      </p>

      <p>
        JH: Yea, yea definitely. I was just talking to somebody about that
        yesterday. That I've always been interested in that. When I was a kid I
        was really fascinated about the moment that you go from being awake to
        being asleep. I thought of it as a place, and I tried to remain awake
        till I got to that place, because I wanted to know more about it. I
        wanted to know if it was a wooden door frame that you pass through, or a
        cave, because I felt like people pass through it so quickly that they
        didn't remember it. And so I wanted to find that spot, and just feel it
        out. I feel like some of the drawings that we're just talking about now
        were doing a very similar thing, not necessarily at the point of sleep
        but in the present moment which is a similar place.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/mastodon800.jpg"
        alt="Mastodon poster by John Howard"
      />

      <p className="interviewItalic">
        EA: Well not everybody has such vivid dreams, but yea it's definitely
        interesting.{" "}
      </p>

      <p>JH: How bout yourself, can you do that?</p>

      <p className="interviewItalic">
        EA: Sometimes I have some dreams that are almost exactly like being
        awake. Everything is the same almost, like the detail, and I could kind
        of get up close and study things and it's like it's actually there. So I
        kind of know what you mean about trying to analyze some of it. I guess
        it's kind of a mystery, we still don't really know what dreams are. If
        you don't mind me asking, has any of your inspiration come from
        psychedelics?{" "}
      </p>

      <p>
        JH: Yea. I haven't done tons of psychedelics but definitely it's
        influenced me in a lot of ways. In different ways besides even visually.
        There's a certain aspect of some psychedelics, like when I was a lot
        younger, acid was pretty good, and I did it a few times, and one of the
        things that you learn in that, is the ability to step outside of
        yourself and trust yourself in that way. Because you could be having a
        particularly intense time that could be disturbing, and so, if you do
        that a few times, you learn to step out of yourself and go, you know,
        "you took some acid, and you're having an intense experience, and it's
        not going to last forever." And you say back to yourself, "okay cool."
        And something very similar to that happens a lot of times in making art
        as well. You can detach, I guess, in a way. So that's one of the things.
        And having done it, it opens your mind a little bit. I remember doing
        mushrooms years ago and I found them frustrating sometimes because the
        visuals are so strong but you can't capture them. They're just going and
        going and going and so, that kind of thing was influential I think to
        some degree- other ways of seeing, if nothing else.{" "}
      </p>

      <p className="interviewItalic">
        EA: I think that makes sense. I think Terence McKenna said it was like a
        "boundary dissolution." But what you're saying makes sense also, about
        stepping outside yourself.{" "}
      </p>

      <p>
        JH: I like that, boundary dissolution. Yea, yea that makes sense. A lot
        of things do that. Looking at other people's art does that. Where you
        have a contained version of what you think is possible without knowing
        it, and something pops that bubble a little bit and suddenly boom,
        you've got another whole room that you can exist in.{" "}
      </p>

      <p>
        EA: Do you think it's possible that there's life in the universe? If so,
        do you think maybe some life somewhere else has some sort of concept of
        art the way that we have a concept of art?{" "}
      </p>

      <p>
        JH: Yea...Personally, I think of it a little bit more dimensionally than
        like space, planets and things. But to try to answer the question, I'm
        not so sure about that. I mean on a base level probably, as far as
        having different ways of communicating. But I mean, even animals on
        Earth for example have so many different ways of seeing, whether they
        see colors, or what kind of depth, or they see lateral movement more
        than vertical because that's how they hunt, and all these different
        things. To think that the way I draw pictures is going to mean anything
        to someone that's outside of my species is a little bit far-fetched to
        me I think. But the same way of taking the common way of experiencing
        reality and getting a little bit outside of it and looking back,
        accomplishes the phrase that you just said, where you're expanding
        dimensionally what's conceivable. And I think that would probably be
        part of any society that has that type of intelligence, beyond just sort
        of survival intelligence.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/blackangels-jhoward-barca800.jpg"
        alt="Black Angels poster by John Howard"
      />

      <p className="interviewItalic">
        EA: Did the pandemic affect you, did it make it more difficult for you
        to do what you do?
      </p>

      <p>
        JH: Well as far as posters for sure. I mean, there were no events. So
        that had a direct impact on making posters you know. I did a few events,
        I did a couple online events, other things like that, but basically what
        I think happened to a lot of people, where you know, what was normal
        just kind of fell through- gave me time and space to reassess a lot of
        things. And it's not completely evident quite yet, but yea I feel that
        there's a good thing that has come about, in that people have been
        forced to stop and reassess their lives and re-decide some things that
        they had closed the book on. So I definitely did that, and the fruits of
        that are in progress.{" "}
      </p>

      <p className="interviewItalic">
        EA: Do you have any ghost stories or anything paranormal that's ever
        happened to you?{" "}
      </p>

      <p>
        JH: Yea, lots of stuff my whole life. I wouldn't know exactly where to
        start. But yea, from childhood lots of stuff. There's been a lot of it
        that's connected to art too. I draw a lot on the road, and there's been
        a lot of things that...kind of a predictive thing that has happened.
        It's hard to just throw things out there like that, but yea I have a
        pretty strong belief in…there's so many different ways you can say it,
        none of them really accurate, but almost like a…a spirit of humanity
        that exists that I tap into, for different reasons for art, and also I
        have a very consistent meditation practice over the last many years. I
        plug into it that way too, and so I guess the short answer is just yes.
        It's not a very entertaining answer.{" "}
      </p>

      <p className="interviewItalic">
        EA: I'm sure a lot of it is like really long stories that are probably a
        little too complex to describe, but I like what you said about
        meditation. I guess it is possible that there is some sort of collective
        spirit or something like that, that makes sense.{" "}
      </p>

      <p>
        JH: I definitely believe that. I definitely feel that, it's a source of
        serenity for me honestly.{" "}
      </p>

      <p className="interviewItalic">
        EA: I saw that you did a poster for the band Earthless, they're here in
        San Diego.{" "}
      </p>

      <p>
        JH: Yea, yea great band. I've done at least a couple other Earthless
        posters but anyway, a great band.{" "}
      </p>

      <p className="interviewItalic">
        EA: Yea, I never really got a chance to see them but I have listened to
        a lot of their music and it's kind of unbelievable, the guy just does
        this guitar solo for like 30 minutes, it's definitely mind-blowing.{" "}
      </p>

      <p>
        JH: Yea, very cool. It's great music to work with, it keeps going for a
        while, it gives you a little headspace to work for a continued amount of
        time, which is nice.{" "}
      </p>

      <p className="interviewItalic">EA: Do you go to a lot of concerts? </p>

      <p>
        JH: Yea, I haven't in a few years, but generally speaking I get to see a
        lot of music when I do festivals. The last time that my last Earthless
        poster was, I did a poster for their Oakland show that was here, and
        then I think their next show after that was at Desert Daze and I was
        showing at Desert Daze. So I got to see them, they played right behind
        my booth there. And so anyway, the way it's been working over the last
        number of years is I generally get to see pretty much everybody, or a
        lot of good shows when I do festivals, and I don't go to a lot of shows
        from here, but I do catch some.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/earthlessoakland800.jpg"
        alt="Earthless at the Starline Social Club in Oakland poster by John Howard"
      />

      <p className="interviewItalic">
        EA: Did you ever see Acid Mothers Temple play?{" "}
      </p>

      <p>
        JH: Oh yea, a bunch of times, pretty much every year over the last dozen
        years.{" "}
      </p>

      <p className="interviewItalic">
        EA: I guess why I'm asking is sometimes when you go to those concerts
        it's really loud. I love the music but I would go to these concerts and
        I didn't have earplugs and it was intense, I guess is what I'm trying to
        say.{" "}
      </p>

      <p>
        JH: Yea, I definitely always have some earplugs, it can be pretty
        enjoyable with some decent earplugs.{" "}
      </p>

      <p className="interviewItalic">
        EA: I should have been wearing earplugs, but they were still good
        experiences. What do you think about Kawabata's artwork? He was doing
        some artwork too.{" "}
      </p>

      <p>
        JH: Yea I like it a lot. I've got a bunch of his t-shirts. I usually get
        one every year. I like his work a lot, he's all hand drawing too.{" "}
      </p>

      <p className="interviewItalic">
        EA: He has some really original ideas, I really like him too. Do you
        think you could ever become an art teacher in the future?{" "}
      </p>

      <p>
        JH: That kind of thing has been mentioned a few times recently, and I
        think I would enjoy doing that. I have one friend that teaches studio
        painting at a university and I really, well I envy him, that there are
        people out there in the world making cool shit, that he had something to
        do with it. I like that, that's awesome, you know what I mean? Anyway, I
        think I would like to do that.{" "}
      </p>

      <p className="interviewItalic">
        EA: Especially because you're so passionate about what you do, it seems
        like that's a good direction to go. What is it like seeing your name in
        print or making a project come to life? Does it ever feel strange to you
        or did it ever seem unbelievable at one point?{" "}
      </p>

      <p>
        JH: Good question...I guess I like that...It's not that big a deal to me
        though, it's more that it's just supposed to be on there you know, that
        it's supposed to be an indicator of where this came from.{" "}
      </p>

      <p className="interviewItalic">
        EA: Are you familiar with David Lynch? He said that intuition is
        "emotion and intellect going together." Do you think that's true?{" "}
      </p>

      <p>
        JH: Yea I like that. I like David Lynch a lot, he got me into
        Transcendental Meditation over a decade ago and I've been doing it twice
        a day for a long, long time.{" "}
      </p>

      <p className="interviewItalic">
        EA: Is there any artist throughout history that you would like to meet
        if you had the chance to go back in time?{" "}
      </p>

      <p>
        JH: Yea...maybe meet is not quite the right thing, but I would like
        to...yea meet's good enough. I mean, I would like to hover around and
        watch them do what they do. Francis Bacon, a British painter, he did
        some amazing visceral, abstract, but just completely alive paintings. He
        had a pretty chaotic lifestyle that I wouldn't want to spend too much
        time around...but you know I would like to peek into how the person was
        that created some of this art, how they moved in the world, and how they
        talked, and what they thought was funny a little bit. But anyway, Dürer,
        I've made a lot of connections in my mind with Dürer because he was a
        printmaker too, in the same way I am a designer of prints. And he had
        one of the first big Gutenberg presses, the big wooden presses that, you
        know, two wood blocks are meshed together to make prints and anyway, he
        did these drawings and had them carved into wood and printed, and he and
        his wife traveled Europe going to festivals selling his prints. This is
        in the 1400s and so I felt very connected to him. At the time I was
        doing a lot of showing, back before the pandemic and you know, back for
        a number of years that's what was going down, my wife and I were
        traveling around Europe selling my prints. So I felt a very strong
        connection to him, and his work is just crazy amazing. Really beautiful
        stuff, and there's several stories that happened about him. I did a
        version of his rhino piece.{" "}
      </p>

      <img
        src="https://www.monkeyink.com/ink/blog/images/rhinoDURERRHINO1824-1colorizeflat800.jpg"
        alt="500 years ago Albrecht Dürer published his illustration of a rhinoceros as a wood block print. He drew the rhino, sight unseen, from a written description and small sketch. His drawing was transferred to fruitwood and carved by the formschneider. That block produced 12-15 thousand prints in eight editions. Poster by John Howard"
        style={{ marginBottom: 0 }}
      />
      <figcaption>
        <a
          style={{ textDecoration: "underline" }}
          href="http://monkeyink.com/ink/blog/archives/2014/10/durers_rhino_se.php"
        >
          More information about John Howard's Rhino
        </a>
      </figcaption>

      <p className="interviewItalic">
        EA: It's actually I think the first time I've heard about him. I don't
        think I'm familiar with him.{" "}
      </p>

      <p>
        JH: I did a version of his rhino illustration as an honor to him, and
        took this print around the European circuits one more time. I did some
        versions of it, I did it as a different version. There's a print of his
        with a rock on it under the nose of the rhino. Basically if you look at
        the ground closer to the rhino it's like, printing this print and
        putting it out, and with the rock on it it's ready for sale. And it says
        in Latin on there, "to create, duplicate, and distribute." That's kind
        of the ethos of both of us. And by his name, in the background behind
        his name there's a little light image of that Gutenberg printing press.
        A couple things that are interesting about this print, one is that he
        never saw the rhino. He drew it from a description sent to him from
        Lisbon. He was in Germany, Frankfurt. He liked to draw exotic animals,
        and most often you get a verbal description first. So that's how he drew
        this. And what he would always do, is when he found out more about the
        animal he would correct it and have a new more accurate version of it,
        but on this one, his initials, his name, you see the "AD" that's his
        signature on there. That became more important than the image because he
        was famous. As they would say now, he had branded himself. To the point
        that this drawing was never updated when he found out more about it,
        because the fact that it had, it was original, the original drawing, it
        had AD on it, was more important than the accuracy of the picture. So
        there were just a lot of things about him...he was selling his stuff at
        art festivals, because the other way to be an artist is to either work
        for the church or for rich people. He went out on his own, made his own
        fucking art and sold it to real people like 500 years ago. So anyway,
        that's a guy I would like to hang with a little bit. I would not feel
        worthy, but I would sure like to share a beer with him.{" "}
      </p>

      <p className="interviewItalic">
        EA: Yea, that's a great answer and I feel like I learned something there
        because I wasn't familiar with him...So tell me about your future events
        that you have planned?{" "}
      </p>

      <p>
        JH: Well, there's three things that are definitely on the horizon that I
        could mention, one is the TRPS show in October in Golden Gate Park. And
        then I plan on doing Corona Capital in Mexico City in November and then
        the next one that I have planned would be Primavera Sound in Barcelona.
        So those are like three upcoming things as far as talking about the
        future a little bit.
      </p>

      <p>
        See John’s inspiring artwork for yourself at these upcoming events:{" "}
      </p>

      <p>
        1. TRPS show in Golden Gate Park, Hall of Flowers. October 15, 2022 -{" "}
        <a href="https://trps.org/">https://trps.org/</a>
      </p>

      <p>
        2. Corona Capital, Mexico City. November 18-20, 2022 -{" "}
        <a href="https://www.coronacapital.com.mx/">
          https://www.coronacapital.com.mx/
        </a>
      </p>

      <p>
        3. South by Southwest, Flatstock. March 15-17, 2023 -{" "}
        <a href="https://www.sxsw.com/exhibitions/flatstock/">
          https://www.sxsw.com/exhibitions/flatstock/
        </a>
      </p>
      <p>
        4. Primavera Sound, Barcelona. June 1-June 3, 2023 -{" "}
        <a href="https://www.primaverasound.com/en/">
          https://www.primaverasound.com/
        </a>
      </p>

      <br />
      <br />
      <br />
      <br />
      <br />

      <p>*******</p>

      <h3>
        <a href="/">artnow.world</a>
      </h3>
    </Layout>
  );
};

export default John;
